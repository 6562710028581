import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { useState, useEffect } from "react";
import { Card, Flex, Drawer, Collapse, Select, DatePicker, Breadcrumb} from 'antd';
import "./style.css"
import ReportTable from "../../components/table/ReportTable";
import { IoCalendarClear, IoCaretDown, IoCloseCircleOutline, IoHome, IoFilter, IoPrint } from "react-icons/io5";
import Statistic from "antd/es/statistic/Statistic";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import axios from "axios";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import { permissions } from "../../utils/tools/roles/roles";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const { Header } = Layout;
const { RangePicker } = DatePicker;

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const apiUrl = window.AppConfig.API_ENDPOINT

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Report = () => {
    const [permissionsRole, setPermissionsRole] = useState()
    const [dataset, setDataset] = useState()
    const [date, setDate] = useState([])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "DtFrom": "",
        "DtTo": "",
        "ConcessionariaId": [],
        "PublisherId": [],
        "Site":[] ,
        "Page": 1,
    })      
    const [pager, setPager] = useState()
    const [open, setOpen] = useState(false);
    const [dealershipList, setDealershipList] = useState([]);
    const [fullPublisherList, setFullPublisherList] = useState([]); 
    const [publisherList, setPublisherList] = useState([]); 
    const [fullWebsiteList, setFullWebsiteList] = useState([]);
    const [websiteList, setWebsiteList] = useState([]); 
    const [sidebarFilters, setSidebarFilters] = useState({dealership: [], publisher: [], website: [], date: ""})
    const [isLoading, setIsLoading] = useState(false)
    const [loggedUser, setLoggedUser] = useState(null);
    const [cardData, setCardData] = useState({ ecpmGross: 0, adRequest: 0, paidImpression: 0, fillRate: 0, ecpmEditore: 0, revenueGross: 0, revenueEditore: 0 })

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/reports/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data)
                    setPager(response.data.pager)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }, [filtersApi, loggedUser])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/init/list-concessionarie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value)}))
                    setDealershipList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-publishers`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value), text1: parseInt(element.text1)}))
                    setPublisherList(list)
                    setFullPublisherList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-inventory`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: element.text, text1: parseInt(element.text1)}))
                    setWebsiteList(list)
                    setFullWebsiteList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser])

    const setFiltersSidebar = (type, values) => {
        switch (type) {
            case "dealership": setSidebarFilters(prevState => ({
                ...prevState, dealership: values}))
                break;
            case "publisher": setSidebarFilters(prevState => ({
                ...prevState, publisher: values}))
                break;
            case "website": setSidebarFilters(prevState => ({
                ...prevState, website: values}))
                break;
            case "date": setSidebarFilters(prevState => ({
                ...prevState, date: values}))
                break;
            case "apply": handleFilter()
                break;
        }
    };

    const handlePrint = () => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/reports/print-report`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'text/csv' });
    
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = 'data.csv';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    const handleFilter = () => {
        setIsLoading(true)
        setOpen(false)
        setFiltersApi(prevState => ({
            ...prevState, 
            ConcessionariaId: sidebarFilters.dealership, 
            PublisherId: sidebarFilters.publisher,
            Site: sidebarFilters.website,
        }));
    }

    const handleRangeChange = (value) => {
        const val = value ?? ["", ""]
        setDate(val)
        setFiltersApi(prevState => ({
            ...prevState, 
            DtFrom: value ? value[0].format("DD/MM/YYYY") : "", 
            DtTo: value ? value[1].format("DD/MM/YYYY") : ""
        }));
    }

    useEffect(() => {
        if (sidebarFilters.dealership.length > 0) {
            const filteredPublishers = fullPublisherList.filter(pub =>
                sidebarFilters.dealership.includes(pub.text1)
            );
            setPublisherList(filteredPublishers);
        } else {
            setPublisherList(fullPublisherList); 
        }
    }, [sidebarFilters.dealership, fullPublisherList]);

    useEffect(() => {
        const { publisher, dealership } = sidebarFilters;
    
        if (publisher.length > 0 || dealership.length > 0) {
            const filteredWebsites = fullWebsiteList.filter(site => {
                const publisherMatch = publisher.some(pub => pub.value === site.text1);
                const dealershipMatch = dealership.some(dealer => dealer.value === site.text1);
                return publisherMatch && dealershipMatch;
            });
            setWebsiteList(filteredWebsites);
        } else {
            setWebsiteList(fullWebsiteList);
        }
    }, [sidebarFilters.publisher, sidebarFilters.dealership, fullWebsiteList]);

    useEffect(() => {
        if (loggedUser && dataset) { 
            switch (loggedUser.user.ruolo) {
                case "Admin": setCardData({
                    ecpmGross: dataset.totals.ecpmGross,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    ecpmEditore: dataset.totals.ecpmConc,
                    revenueGross: dataset.totals.revenueGross,
                    revenueEditore: dataset.totals.revenueEditore
                })
                    break;
                case "Concessionaria": setCardData({
                    ecpmGross: dataset.totals.ecpmConc,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    ecpmEditore: dataset?.totals.ecpmPubl,
                    revenueGross: dataset.totals.revenueConc,
                    revenueEditore: dataset.totals.revenuePubl
                })
                    break;
                case "Publisher": setCardData({
                    ecpmGross: dataset.totals.ecpmPubl,
                    adRequest: dataset.totals.impressions,
                    paidImpression: dataset.totals.paidImpressions,
                    fillRate: dataset.totals.fillRate,
                    revenueGross: dataset.totals.revenuePubl,
                    ecpmEditore: null,
                    revenueEditore: null,
                })
                    break;
            } 
        }
    }, [dataset, loggedUser])

    const handleRangePresets = (value) => {
        switch (value) {
            case "year": setDate([dayjs().subtract(1, "year"), dayjs()])
                break;
            case "month": setDate([dayjs().subtract(1, "month"), dayjs()])
                break;
            case "week": setDate([dayjs().subtract(1, "week"), dayjs()])
                break;
            case "day": setDate([dayjs().subtract(1, "day"), dayjs()])
                break;
            default: setDate([])
        }
    }

    useEffect(() => {
        console.log(date)
        if (date.length > 0 && date[0] !== "") {
            setFiltersApi(prevState => ({
                ...prevState, 
                DtFrom: date[0].format('DD/MM/YYYY'),
                DtTo: date[1].format('DD/MM/YYYY')
            }));
        } else {
            setFiltersApi(prevState => ({
                ...prevState, 
                DtFrom: "",
                DtTo: ""
            }));
        }
    }, [date])

    const items = [
        {
            key: '1',
            label: 'Concessionarie',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select concessionarie"
                onChange={(values) => setFiltersSidebar("dealership", values)}
                options={dealershipList}
            />,
        },
        {
            key: '2',
            label: 'Publisher',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select publisher"
                onChange={(values) => setFiltersSidebar("publisher", values)}
                options={publisherList}
            />,
        },
        {
            key: '3',
            label: 'Sito',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select sites"
                onChange={(values) => setFiltersSidebar("website", values)}
                options={websiteList}
            />,
        },
    ];

    return (
        <>
            <Loading visible={isLoading} />
            <Drawer width={500} className="filter-drawer" title="Filtri" open={open} onClose={() => setOpen(false)} closeIcon={<IoCloseCircleOutline size={28}/>}>
                <Collapse className="custom-collapse" items={items} expandIconPosition="end"
                    expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                />
                <Flex justify="right" align="center">
                    <button onClick={() => handleFilter()} className="save-button w-100">Applica</button>
                </Flex>
            </Drawer>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Reports</div>
                        </div>
                        <div className="filter-section">
                            <div>
                                <IoCalendarClear className="custom-calendar-icon" />
                                <RangePicker
                                    suffixIcon={<IoCaretDown/>}
                                    allowClear={false}
                                    onChange={handleRangeChange}
                                    className="filter date-range-filter"
                                    defaultValue={date}
                                    value={date}
                                    format="DD-MM-YYYY"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                        borderRadius: '16px',
                                        padding: '0 1rem',
                                        border: 'none',
                                        background: '#8080801f',
                                        color: 'black',
                                        cursor: 'pointer',
                                        height: '2rem'
                                    }}
                                />
                            </div>
                            <div>
                                <Select
                                    allowClear
                                    optionFilterProp="label"
                                    suffixIcon={<IoCaretDown style={{color: 'black'}} />}
                                    placeholder="Preset date"
                                    style={{
                                        width: '180px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                        borderRadius: '16px',
                                        padding: '0 1rem',
                                        border: 'none',
                                        background: '#8080801f',
                                        color: 'black',
                                        fontWeight: 600,
                                        cursor: 'pointer',
                                        height: '2rem',
                                    }}
                                    onChange={(value) => handleRangePresets(value)}
                                    options={[
                                        {label: "Ultimo anno", value: "year"},
                                        {label: "Ultimo mese", value: "month"},
                                        {label: "Ultima settimana", value: "week"},
                                        {label: "Ultimo giorno", value: "day"},
                                    ]}
                                />
                            </div>
                            <button className="print-button" onClick={handlePrint}>
                                Stampa
                                <IoPrint size={15} />
                            </button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Report',
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Carousel className="my-carousel" responsive={responsive} infinite removeArrowOnDeviceType={["tablet", "mobile"]}>
                        <Card className="dashboard-card" style={{backgroundColor: "#F9EBDD"}}>
                            <span className="dashboard-category">ECPM Gross</span>
                            <Statistic className="dashboard-value" groupSeparator="." value={cardData.ecpmGross} precision={2} suffix="€" />
                        </Card>
                        <Card className="dashboard-card" style={{backgroundColor: "#F0F4F8"}}>
                            <span className="dashboard-category">Ad Request</span>
                            <Statistic className="dashboard-value" groupSeparator="." value={cardData.adRequest} precision={0} />
                        </Card>
                        <Card className="dashboard-card" style={{backgroundColor: "#ECDAFF"}}>
                            <span className="dashboard-category">Paid Impression</span>
                            <Statistic className="dashboard-value" groupSeparator="." value={cardData.paidImpression} precision={0} />
                        </Card>
                        <Card className="dashboard-card" style={{backgroundColor: "#F6F9DD"}}>
                            <span className="dashboard-category">Fill Rate</span>
                            <Statistic className="dashboard-value" groupSeparator="." value={cardData.fillRate} precision={0} suffix="%"/>
                        </Card>
                        {
                            permissionsRole?.canSeeECPMEditoreCard &&
                            <Card className="dashboard-card" style={{backgroundColor: "#DDF9E1"}}>
                                <span className="dashboard-category">ECPM Editore</span>
                                <Statistic className="dashboard-value" groupSeparator="." value={cardData.ecpmEditore} precision={2} suffix="€"/>
                            </Card>
                        }
                        <Card className="dashboard-card" style={{backgroundColor: "#CFFFF2"}}>
                            <span className="dashboard-category">Revenue Gross</span>
                            <Statistic className="dashboard-value" groupSeparator="." value={cardData.revenueGross} precision={0} suffix="€"/>
                        </Card>
                        {
                            permissionsRole?.canSeeRevenueEditore &&
                            <Card className="dashboard-card" style={{backgroundColor: "#FFD0E6"}}>
                                <span className="dashboard-category">Revenue Editore</span>
                                <Statistic className="dashboard-value" groupSeparator="." value={cardData.revenueEditore} precision={0} suffix="€"/>
                            </Card>
                        }
                    </Carousel>
                    <Flex className="filter-section no-padding" justify="flex-end">
                        <button className="filter" onClick={() => setOpen(true)}>
                            <IoFilter />
                            Filtri
                        </button>
                    </Flex>
                    <ReportTable data={dataset?.reports} setFiltersApi={setFiltersApi} pageInfo={pager} user={loggedUser?.user}/>
                </div>
            </Navigation>
        </>
    )
}

export default Report;