import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

const PieChart = ({ data }) => {
    // Group the data by site
    const aggregatedData = data.reduce((acc, item) => {
        if (!acc[item.site]) {
            acc[item.site] = 0;
        }
        acc[item.site] += item.value;
        return acc;
    }, {});

    // Transform the aggregated data for the pie chart
    const transformedData = Object.keys(aggregatedData).map(site => ({
        value: aggregatedData[site],
        name: site
    }));

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: ({ name, value, percent }) => {
                return `Site: ${name}<br/>Value: ${value.toFixed(2)}<br/>Percentage: ${percent}%`;
            }
        },
        legend: {
            orient: 'vertical',
            left: 'right',
            top: 'middle',
            type: 'scroll',
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                avoidLabelOverlap: false,
                radius: ['40%', '70%'], // Adjust radius to make the pie smaller
                center: ['30%', '50%'], // Adjust center to reposition the pie
                data: transformedData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                }
            }
        ]
    };
    

    return (
        <ReactECharts option={option} />
    );
};

export default PieChart;
