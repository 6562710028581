import { useState, useEffect, useRef } from 'react';
import { Input, Flex, Upload, Pagination, Card, Row, Col, Form, Select, Collapse, Drawer, Breadcrumb } from 'antd';
import Loading from '../../components/loading/Loading';
import Navigation from '../../components/navigation/Navigation';
import Layout from 'antd/es/layout/layout';
import { CiCirclePlus } from "react-icons/ci";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleOutline, IoFilter, IoHome, IoSearch } from 'react-icons/io5';
import axios from 'axios';
import { UserManager } from '../../utils/tools/user-manager/UserManager';
import "./style.css"

dayjs.extend(relativeTime);

const { Header } = Layout;
const { Dragger } = Upload;
const { TextArea } = Input;

const apiUrl = window.AppConfig.API_ENDPOINT

const Video = () => {
    const [file, setFile] = useState(null);
    const [dataset, setDataset] = useState([])
    const [overlayStates, setOverlayStates] = useState([]);
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "CategorieId": [],
        "TagsId": [],
        "Page": 1,
    })      
    const [pager, setPager] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [sidebarFilters, setSidebarFilters] = useState({tag: [], category: []})
    const [tag, setTag] = useState([])
    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);

    const videoRefs = useRef([]);
    
    const navigate = useNavigate();

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/video/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data);
                    setPager(response.data.pager);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-categorie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const categories = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setCategory(categories)
                })
                .catch(error => {
                    console.error('Error:', error);
                });

            axios.post(`${apiUrl}/api/v1/tagcategorie/get-list-tags`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const tags = response.data.data.map((r) => ({label: r.nome, value: r.id}))
                    setTag(tags)
                })
                .catch(error => {
                    console.log('Error:', error);
                });
        }
    }, [filtersApi, loggedUser])

    useEffect(() => {
        if (dataset.length > 0 && overlayStates.length === 0) {
            setOverlayStates(dataset.map(() => true));
        }
    }, [dataset])

    const items = [
        {
            key: '1',
            label: 'Categorie',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select category"
                onChange={(values) => setFiltersSidebar("category", values)}
                options={category}
            />,
        },
        {
            key: '2',
            label: 'Tag',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select tags"
                onChange={(values) => setFiltersSidebar("tag", values)}
                options={tag}
            />,
        },
    ];
    
    const handleVideoClick = (index) => {
        const video = videoRefs.current[index];
        console.dir(video)
        if (video.paused) {
            video.play();
            setOverlayStates((prev) => prev.map((state, i) => (i === index ? false : state)));
        } else {
            video.pause();
            setOverlayStates((prev) => prev.map((state, i) => (i === index ? true : state)));
        }
    };

    const handleVideoEnded = (index) => {
        setOverlayStates((prev) => prev.map((state, i) => (i === index ? true : state)));
    };

    const handleUploadVideo = (values) => {
        if (loggedUser) {
            const formData = new FormData();
            formData.append('file', file);

            axios.post(`${apiUrl}/api/v1/video/upload/0`, formData, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                const data = {
                    "id": response.data.data.id,
                    "titolo": values.name,
                    "descrizione": values.description,
                    "fileUrl": response.data.data.fileUrl,
                    "categoriaId": values.category,
                    "tags": values.tag,
                    "dimensione": response.data.data.dimensione,
                    "utentiId": loggedUser.user.id,
                    "status": "attivo"
                }                
                axios.put(`${apiUrl}/api/v1/video/upsert`, data, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                    },
                })
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)
            });
            setFile(null);
            
        }
    }

    const setFiltersSidebar = (type, values) => {
        switch (type) {
            case "tag": setSidebarFilters(prevState => ({
                ...prevState, tag: values}))
                break;
            case "category": setSidebarFilters(prevState => ({
                ...prevState, category: values}))
                break;
            case "apply": handleFilter()
                break;
        }
    };

    const handleFilter = () => {
        setIsLoading(true)
        setOpen(false)
        setFiltersApi(prevState => ({
            ...prevState, 
            CategorieId: sidebarFilters.category,
            TagsId: sidebarFilters.tag,
        }));
    }

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const pageChange = (event) => {
        setCurrentPage(event)
        setFiltersApi(prevState => ({
            ...prevState, 
            Page: event
        }));
    }

    return (
        <>
            <Loading visible={isLoading} />
            <Drawer width={500} className="filter-drawer" title="Filtri" open={open} onClose={() => setOpen(false)} closeIcon={<IoCloseCircleOutline size={28}/>}>
                <Collapse className="custom-collapse" items={items} expandIconPosition="end"
                    expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                />
                <Flex justify="right" align="center">
                    <button onClick={() => setFiltersSidebar("apply", null)} className="save-button w-100">Applica</button>
                </Flex>
            </Drawer>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Video</div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Video',
                            className: 'active-breadcrumb'
                        },
                        ]}
                    />
                    <Flex align='center' gap="large" className="filter-section no-padding">
                        <Input value={searchQuery} onChange={handleChange} placeholder="Cerca" style={{ width: '98%', background: "#F7F8F3", height: "50px"}} prefix={<IoSearch />} />
                        <button className="filter" onClick={() => setOpen(true)}>
                            <IoFilter />
                            Filtri
                        </button>
                    </Flex>
                    
                    {
                        file ? 
                        <Flex gap="small">
                            <Card className="file-video-card">
                                <Card className="inner-upload-card">
                                    <Flex vertical>
                                        <span className="file-size">{(file.size / 1048576).toFixed(2)} MB</span>
                                        <span className="filename">{file.name}</span>
                                    </Flex>
                                </Card>
                            </Card>
                            <Card className="form-card file-video-card" title="Dati Video">
                                <Form
                                    layout="inline"
                                    name="anagrafica"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={handleUploadVideo}
                                >
                                    <Flex vertical gap="large" style={{width: "100%"}}>
                                        <Flex>
                                            <Form.Item
                                                name="name"
                                                style={{width: "48%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input name!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nome" />
                                            </Form.Item>

                                            <Form.Item
                                                name="category"
                                                style={{width: "48%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input category!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    allowClear
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select category"
                                                    options={category}
                                                />
                                            </Form.Item>
                                        </Flex>
                                            <Form.Item
                                                name="tag"
                                                style={{width: "100%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input tag!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    mode="multiple"
                                                    allowClear
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select tags"
                                                    options={tag}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="description"
                                                style={{width: "100%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input tag!',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={3} placeholder="Descrizione" />
                                            </Form.Item>                                 
                                        <Flex justify="right" align="center">
                                            <button htmltype="submit" className="save-button">Salva</button>
                                        </Flex>
                                    </Flex>   
                                </Form>
                            </Card>
                        </Flex> :
                        <Dragger name='file'
                                accept='video/*'
                                beforeUpload={(file) => {
                                    setFile(file);
                                    return false;
                                }}
                                onRemove={() => setFile(null)}
                                className="drag-drop">
                            <div className="drag-container">
                                <CiCirclePlus size="5em" />
                                <span className="drag-drop-text">Drag & Drop</span>
                            </div>
                        </Dragger>
                    }
                    

                    <Row gutter={[68, 46]} className="card-container">
                        {dataset.map((data, index) => (
                            <Col
                                xs={24}      // 1 column for extra small screens
                                sm={24}      // 2 columns for small screens
                                md={12}       // 2 columns for medium screens
                                lg={12}       // 3 columns for large screens
                                xl={8}       // 3 columns for extra large screens
                                key={data.id}
                                className="card-video-column"
                            >
                                <Card
                                    key={data.id}
                                    className="video-card"
                                    cover={
                                        <div className="image-container" onClick={() => handleVideoClick(index)}>
                                            <video
                                                ref={(el) => (videoRefs.current[index] = el)}
                                                className="video-content"
                                                onEnded={() => handleVideoEnded(index)}
                                                controls={false}
                                            >
                                                <source src={data.fileUrl} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                            {overlayStates[index] && (
                                                <div className="overlay-video">
                                                    <Flex vertical className="icon">
                                                        <img alt="video" src="/custom-icons/Play.svg" className="video-icon" />
                                                    </Flex>
                                                </div>
                                            )}
                                        </div>
                                    }
                                >
                                    <span onClick={() => navigate(`/video/${data.id}`)}>
                                        <Flex justify='space-between' align='center'>
                                            <span className="card-title">{data.titolo}</span>
                                            <div className="category-box">{category.find(c => c.value === data.categoriaId)?.label}</div>
                                        </Flex>
                                        <span className="card-time">{dayjs(data.dtCreazione).fromNow()}</span>
                                        <div className="tag-section">
                                            {data.tags.map(item => tag.find(t => t.value === item)?.label)}
                                        </div>
                                    </span>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Pagination defaultPageSize={20} current={currentPage} onChange={pageChange} total={pager?.totalItems} />
                    
                </div>
                
            </Navigation>
        </>
    )
}

export default Video;