import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Spin } from "antd";
import Cookies from "js-cookie";

const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        Cookies.remove('loggedUser');
        navigate("/login");   
    }, []);
    
    return (
        <Spin size="large" />
    )
}

export default Logout;