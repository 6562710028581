import { AiOutlineLoading } from "react-icons/ai";
import "./style.css"

const Loading = ({visible}) => {
    return (
        <>
            {
                visible &&
                <div className="loading-screen">
                    <AiOutlineLoading size={50} className="spin" />
                </div>
            }
        </>
    )
}

export default Loading;