import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

const BarChart = ({ data }) => {
    // Transform the data
    const xData = data.map(item => dayjs(item.dtReport).format('DD/MM/YYYY'));
    const yData = data.map(item => item.value);

    const option = {
        xAxis: {
            type: 'category',
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                xAxisIndex: [0]
            }
        ],
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                const { value, axisValue, dataIndex } = params[0];
                const site = data[dataIndex].site;
                return `Date: ${axisValue}<br/>Value: ${value}<br/>Site: ${site}`;
            }
        },
        series: [
            {
                data: yData,
                type: 'bar'
            }
        ]
    };

    return (
        <ReactECharts option={option} />
    );
};

export default BarChart;
