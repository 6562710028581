import { FaPlayCircle } from "react-icons/fa";
import { MdPlaylistAddCircle } from "react-icons/md";
import { BiSolidWidget } from "react-icons/bi";
import { IoStatsChartSharp } from "react-icons/io5";
import { UserManager } from "../tools/user-manager/UserManager";
import { useState, useEffect } from "react";
import { permissions } from "../tools/roles/roles";

export const VideoMenuItems = () => {
    const [permissionsRole, setPermissionsRole] = useState()

    const showVideoSection = window.AppConfig.SHOW_VIDEO_SECTION

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    const filterMenuItems = (items) => {
        return items
            .filter(item => item.permission)
            .map(({ permission, children, ...rest }) => {
                if (children) {
                    return {
                        ...rest,
                        children: filterMenuItems(children)
                    };
                }
                return rest;
            });
    };

    const menuItems = [
        {
            key: "video",
            icon: <FaPlayCircle />,
            label: "Video",
            permission: showVideoSection
        },
        {
            key: "playlist",
            icon: <MdPlaylistAddCircle />,
            label: "Playlist",
            permission: showVideoSection
        },
        {
            key: "widget",
            icon: <BiSolidWidget />,
            label: "Widget",
            permission: permissionsRole?.canSeeWidgetMenu
        },
        {
            key: "report_main",
            icon: <IoStatsChartSharp />,
            label: "Report",
            permission: true,
            children: [
                {
                    key: 'report_upload',
                    label: 'Upload Report',
                    permission: permissionsRole?.canSeeReportUpload
                },
                {
                    key: 'report',
                    label: 'Reports',
                    permission: true
                }
            ]
        }
    ]

    return filterMenuItems(menuItems);

}