export const roles = {
    ADMIN: "Admin",
    CONCESSIONARIA: "Concessionaria",
    PUBLISHER: "Publisher"
};

export const permissions = {
    [roles.ADMIN]: {
        canSeeConcessionariaMenu: true,
        canSeeWidgetMenu: true,
        canSeeInventoryMenu: true,
        canSeeECPMEditoreCard: true,
        canSeePubRevColumn: true,
        canSeeTotalConcessionaria: true,
        canSeeTotalPublisher: true,
        canSeeRevenueEditore: true,
        canSeeSettings: true,
        canSeeReportUpload: true
    },
    [roles.CONCESSIONARIA]: {
        canSeeConcessionariaMenu: false,
        canSeeWidgetMenu: false,
        canSeeInventoryMenu: false,
        canSeeECPMEditoreDashboardCard: true,
        canSeePubRevColumn: true,
        canSeeTotalConcessionaria: false,
        canSeeTotalPublisher: true,
        canSeeRevenueEditore: true,
        canSeeSettings: true,
        canSeeReportUpload: false
    },
    [roles.PUBLISHER]: {
        canSeeConcessionariaMenu: false,
        canSeeWidgetMenu: false,
        canSeeInventoryMenu: false,
        canSeeECPMEditoreDashboardCard: false,
        canSeePubRevColumn: false,
        canSeeTotalConcessionaria: false,
        canSeeTotalPublisher: false,
        canSeeRevenueEditore: false,
        canSeeSettings: false,
        canSeeReportUpload: false
    }
};
