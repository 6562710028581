import { Table, Input } from "antd";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Statistic from "antd/es/statistic/Statistic";
import dayjs from "dayjs";

const ReportTable = ({data, setFiltersApi, pageInfo, user}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setTableData(data)
    }, [data])

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'DtCreazione',
            width: 110,
            render: (row, elem) => dayjs(elem.DtCreazione).format("DD/MM/YYYY")
        },
        {
            title: 'Company',
            dataIndex: 'publisherName',
            width: 150,
            render: (row, elem) => <a className="name-col" href={`/dashboard/${elem.publisherId}`}>{elem.publisherName}</a>
        },
        {
            title: 'Site',
            dataIndex: 'sito',
            width: 200
        },
        {
            title: 'Zone',
            dataIndex: 'zona',
            width: 200
        },
        {
            title: 'Month',
            dataIndex: 'mese',
            width: 110
        },
        {
            title: 'Paid Impr.',
            dataIndex: 'PaidImpressions',
            width: 100,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.paidImpressions} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Gross Rev.',
            dataIndex: 'EstGrosRevenue',
            width: 100,
            render: (row, elem) => {
                let value
                switch (user?.ruolo) {
                    case "Admin": value = elem.estGrosRevenue
                        break;
                    case "Concessionaria": value = elem.estConcRevenue
                        break;
                    case "Publisher": value = elem.estPublRevenue
                        break;
                }

                return <Statistic className="table-statistic" groupSeparator="." value={value} decimalSeparator="," precision={2} suffix="€"/>
            }
        },
        {
            title: 'Pub Rev.',
            dataIndex: 'EstPublRevenue',
            width: 100,
            render: (row, elem) => {
                let value
                switch (user?.ruolo) {
                    case "Admin": value = elem.estConcRevenue
                        break;
                    case "Concessionaria": value = elem.estPublRevenue
                        break;
                    case "Publisher": value = 0
                        break;
                }

                return <Statistic className="table-statistic" groupSeparator="." value={value} decimalSeparator="," precision={2} suffix="€"/>
            },
        },
        {
            title: 'AdRequest',
            dataIndex: 'Impressions',
            width: 110,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.impressions} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Fill Rate',
            dataIndex: 'FillRate',
            width: 100,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.fillRate} decimalSeparator="," precision={2} suffix="%"/>
        },
        {
            title: 'Clicks',
            dataIndex: 'Clicks',
            width: 80,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.clicks} decimalSeparator="," precision={2}/>
        },
        {
            title: 'eCPM',
            dataIndex: 'Ecpm',
            width: 80,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.ecpmConc} decimalSeparator="," precision={2} suffix="€"/>
        },
        {
            title: 'Viewability',
            dataIndex: 'Viewability',
            width: 110,
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.viewability} decimalSeparator="," precision={2} suffix="%"/>
        },
    ];
    
    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const totalElements = pageInfo?.totalItems;
    
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 100,
                    total: totalElements,
                }}
                scroll={{y: 840, x: 1300}}
                rowClassName={rowClassName}
                pageSize={pageInfo?.pager?.totalPages}
                onChange={(data) => {
                    setFiltersApi(prevState => ({
                        ...prevState, 
                        Page: data.current
                    }));
                }}
            />
        </>
    )
}

export default ReportTable;