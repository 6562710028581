import * as ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./routes/dashboard/Dashboard";
import Inventory from "./routes/inventory/Inventory";
import ReportUpload from "./routes/report/ReportUpload";
import Report from "./routes/report/Report";
import Widget from "./routes/widget/Widget";
import Account from "./routes/Account";
import Video from "./routes/video/Video";
import Playlist from "./routes/playlist/Playlist";
import Dealership from "./routes/dealership/Dealership";
import NewDealership from "./routes/dealership/NewDealership";
import NewInventory from "./routes/inventory/NewInventory";
import VideoDetails from "./routes/video/VideoDetails";
import PlaylistDetails from "./routes/playlist/PlaylistDetails";
import NewWidget from "./routes/widget/NewWidget";
import Chatbot from "./components/chatbot/Chatbot";
import Login from "./routes/login/Login";
import Logout from "./routes/login/Logout";
import Editor from "./routes/video/editor/Editor";
import Tag from "./routes/settings/Tag";
import Category from "./routes/settings/Category"
import New from "./routes/settings/New"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/dashboard/:id",
    element: <Dashboard />
  },
  {
    path: "/dealership",
    element: <Dealership />
  },
  {
    path: "/dealership/:id",
    element: <NewDealership />
  },
  {
    path: "/dealership/:id",
    element: <NewDealership />
  },
  {
    path: "/inventory",
    element: <Inventory />
  },
  {
    path: "/inventory/new",
    element: <NewInventory />
  },
  {
    path: "/inventory/:id",
    element: <NewInventory />
  },
  {
    path: "/video",
    element: <Video />
  },
  {
    path: "/video/:id",
    element: <VideoDetails />
  },
  {
    path: "/video/editor",
    element: <Editor />
  },
  {
    path: "/playlist",
    element: <Playlist />
  },
  {
    path: "/playlist/:id",
    element: <PlaylistDetails mode={"edit"} />
  },
  {
    path: "/playlist/new",
    element: <PlaylistDetails mode={"new"}/>
  },
  {
    path: "/widget",
    element: <Widget />
  },
  {
    path: "/widget/:id",
    element: <NewWidget />
  },
  {
    path: "/widget/new",
    element: <NewWidget />
  },
  {
    path: "/report_upload",
    element: <ReportUpload />
  },
  {
    path: "/report",
    element: <Report />
  },
  {
    path: "/tag",
    element: <Tag />
  },
  {
    path: "/tag/:type",
    element: <New />
  },
  {
    path: "/category",
    element: <Category />
  },
  {
    path: "/category/:type",
    element: <New />
  },
  {
    path: "/account",
    element: <Account />
  },
  {
    path: "/logout",
    element: <Logout />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Chatbot />
    <RouterProvider router={router} />
  </>
);