import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Select, Drawer, Collapse, Breadcrumb } from 'antd';
import "./style.css"
import InventoryTable from "../../components/table/InventoryTable";
import { useNavigate } from "react-router-dom";
import { IoCloseCircleOutline, IoFilter, IoHome, IoAddCircle } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Inventory = () => {
    const [dataset, setDataset] = useState([])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "ConcessionariaId": [],
        "PublisherId": [],
        "InventoryId": [],
        "Page": 1,      
    })      
    const [pager, setPager] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [dealershipList, setDealershipList] = useState([]);
    const [fullPublisherList, setFullPublisherList] = useState([]); 
    const [publisherList, setPublisherList] = useState([]); 
    const [fullWebsiteList, setFullWebsiteList] = useState([]);
    const [websiteList, setWebsiteList] = useState([]); 
    const [sidebarFilters, setSidebarFilters] = useState({dealership: [], publisher: [], website: []})
    const [trigger, setTrigger] = useState()
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/inventory/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data)
                    setPager(response.data.pager)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }, [filtersApi, trigger, loggedUser])

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/init/list-concessionarie`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value)}))
                    setDealershipList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-publishers`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: parseInt(element.value), text1: parseInt(element.text1)}))
                    setPublisherList(list)
                    setFullPublisherList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    
            axios.post(`${apiUrl}/api/v1/init/list-inventory`, {}, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    const data = response.data
                    const list = data.map(element => ({label: element.text, value: element.text, text1: parseInt(element.text1)}))
                    setWebsiteList(list)
                    setFullWebsiteList(list)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loggedUser])

    const setFiltersSidebar = (type, values) => {

        switch (type) {
            case "dealership": setSidebarFilters(prevState => ({
                ...prevState, dealership: values}))
                break;
            case "publisher": setSidebarFilters(prevState => ({
                ...prevState, publisher: values}))
                break;
            case "website": setSidebarFilters(prevState => ({
                ...prevState, website: values}))
                break;
            case "apply": handleFilter()
                break;
        }
    };

    const handleFilter = () => {
        setIsLoading(true)
        setOpen(false)
        setFiltersApi(prevState => ({
            ...prevState, 
            ConcessionariaId: sidebarFilters.dealership, 
            PublisherId: sidebarFilters.publisher,
            Site: sidebarFilters.website
        }));
    }

    useEffect(() => {
        if (sidebarFilters.dealership.length > 0) {
            const filteredPublishers = fullPublisherList.filter(pub =>
                sidebarFilters.dealership.includes(pub.text1)
            );
            setPublisherList(filteredPublishers);
        } else {
            setPublisherList(fullPublisherList); 
        }
    }, [sidebarFilters.dealership, fullPublisherList]);

    useEffect(() => {
        const { publisher, dealership } = sidebarFilters;
    
        if (publisher.length > 0 || dealership.length > 0) {
            const filteredWebsites = fullWebsiteList.filter(site => {
                const publisherMatch = publisher.some(pub => pub.value === site.text1);
                const dealershipMatch = dealership.some(dealer => dealer.value === site.text1);
                console.log(publisherMatch && dealershipMatch)
                return publisherMatch && dealershipMatch;
            });
            setWebsiteList(filteredWebsites);
        } else {
            setWebsiteList(fullWebsiteList);
        }
    }, [sidebarFilters.publisher, sidebarFilters.dealership, fullWebsiteList]);

    const items = [
        {
            key: '1',
            label: 'Concessionarie',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select concessionarie"
                onChange={(values) => setFiltersSidebar("dealership", values)}
                options={dealershipList}
            />,
        },
        {
            key: '2',
            label: 'Publisher',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select publisher"
                onChange={(values) => setFiltersSidebar("publisher", values)}
                options={publisherList}
            />,
        },
        {
            key: '3',
            label: 'Sito',
            children: <Select
                optionFilterProp="label"
                mode="multiple"
                allowClear
                style={{
                    width: '100%',
                }}
                placeholder="Select sites"
                onChange={(values) => setFiltersSidebar("website", values)}
                options={websiteList}
            />,
        },
    ];

    return (
        <>
            <Loading visible={isLoading} />
            <Drawer width={500} className="filter-drawer" title="Filtri" open={open} onClose={() => setOpen(false)} closeIcon={<IoCloseCircleOutline size={28}/>}>
                <Collapse className="custom-collapse" items={items} expandIconPosition="end"
                    expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                />
                <Flex justify="right" align="center">
                    <button onClick={() => handleFilter("apply", null)} className="save-button w-100">Applica</button>
                </Flex>
            </Drawer>
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Inventory</div>
                        </div>
                        <div className="filter-section">
                            <button onClick={() => navigate("/inventory/new")} className="add-button">
                                Crea
                                <IoAddCircle size={15} />
                            </button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Flex gap="large" align="center" justify="space-between" className="filter-section no-padding">
                        <Breadcrumb
                            separator=">"
                            items={[
                            {
                                href: "/",
                                title: (
                                    <Flex gap="small" align="center">
                                        <IoHome />
                                        Home
                                    </Flex>
                                ),
                            },
                            {
                                title: "Inventory",
                                className: 'active-breadcrumb'
                            }
                            ]}
                        />
                        <button className="filter" onClick={() => setOpen(true)}>
                            <IoFilter />
                            Filtri
                        </button>
                    </Flex>
                    <InventoryTable data={dataset} setFiltersApi={setFiltersApi} pageInfo={pager} trigger={setTrigger}/>
                </div>
            </Navigation>
        </>
    )
}

export default Inventory;