import { IoIosChatbubbles } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import "./style.css"
import { Drawer, Tabs, Flex, Input } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { BsArrowRightCircle } from "react-icons/bs";
import ReactMarkdown from "react-markdown";
import { IoMdSend } from "react-icons/io";
import { UserManager } from "../../utils/tools/user-manager/UserManager";
import { useEffect, useState } from "react";

const ChatHistory = ({data, type="history"}) => {
    return (
        <Flex className="history-section" gap="small" justify="space-between" align="center">
            <Flex gap="large">
                <FaUserCircle color="#CE2472" size={30}/>
                <Flex vertical> 
                    <div className="title">{data.title}</div>
                    {type === "history" && <div className="description">{data.description}</div>}
                </Flex>
            </Flex>
            {type === "history" && <BsArrowRightCircle size={20} />}
        </Flex>
    )
}

const Chat = ({data}) => {
    const messages = [
        { id: 1, text: "ciao, quanto abbiamo guadagnato questa settimana?", sender: 'user', date: "12/8/2024" },
        { id: 2, text: "ciao, il revenue totale è pari a 54.000 euro, + 15 % rispetto il mese precedente", sender: 'ai', date: "14/8/2024" },
        { id: 3, text: "Quali sono i top 3 website che performato meglio?", sender: 'user', date: "15/8/2024" },
        { id: 4, text: "1° www.tiscali.it,\n2° www.medialetaly.com,\n3° www.mipiaciitu.com", sender: 'ai', date: "15/8/2024" },
        { id: 5, text: "Quali sono i top 3 website che performato meglio?", sender: 'user', date: "15/8/2024" },
        { id: 6, text: "1° www.tiscali.it,\n2° www.medialetaly.com,\n3° www.mipiaciitu.com", sender: 'ai', date: "15/8/2024" },
    ];


    
    return (
        <div className="chat-section">
            <ChatHistory data={data} type="chat" />
            <div className="chat-container">
                {messages.map((msg, index) => (
                    <div key={`message-${msg.sender}-${index}`} className={`message-line-${msg.sender}`} >
                            {
                                msg.sender === 'user' ? 
                                    <FaUserCircle className="message-icon-user" color="#CE2472" size={25}/> :
                                    <div className='message-icon-ai'>AI</div>
                            } 
                        <div className={`message-box ${msg.sender}`} key={index}>
                            <div className={'message'}>
                                <ReactMarkdown className="markup">
                                    {msg.text}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                
                ))}
            </div>

            <div className='input-container'>
                <Input className="input-chat" placeholder='Scrivi qui il tuo messaggio'
                    suffix={<IoMdSend className="send-button" color="#CE2472" size={25} />}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                        event.preventDefault();
                        }
                    }}
                />
            </div>
        </div>
    )
}

const Chatbot = () => {
    const [open, setOpen] = useState(false)
    const [loggedUser, setLoggedUser] = useState(null);

    const mockData = [
        {
            id: 1,
            title: "Report 20/05/2024",
            description: "Revenue pari a 50.000€"
        },
        {
            id: 2,
            title: "Report 22/05/2024",
            description: "Revenue pari a 45.000€"
        }
    ]

    const items = [
        {
            key: '1',
            label: <Flex gap="small">
                <IoIosChatbubbles size={20} />
                Chat
            </Flex>,
            children: <Chat data={mockData[0]} />,
        },
        {
            key: '2',
            label: <Flex gap="small">
                <IoReload size={20} />
                Cronologia
            </Flex>,
            children: mockData.map(chat => <ChatHistory data={chat} />),
        },
    ];

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);
    
    return (
        <>
            {
                loggedUser &&
                <>
                    <Drawer className="ai-drawer" width={500} open={open} onClose={() => setOpen(false)}>
                        <Tabs className="ai-tab" defaultActiveKey="1" items={items} />
                    </Drawer>
                    <div className="chatbot-icon" onClick={() => setOpen(true)}>
                        <IoIosChatbubbles size={40} />
                    </div>
                </>
            }
        </>
        
    )
}

export default Chatbot;