import { useNavigate } from 'react-router-dom';
import { Flex } from 'antd';

const BackButton = () => {
    let navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <button onClick={goBack} className="back-button">
            <Flex justify="center" gap="small">
                <img alt="back" src="/custom-icons/Back.svg" />
                Indietro
            </Flex>
        </button>
    )
}

export default BackButton;