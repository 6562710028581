import CreativeEditorSDK from '@cesdk/cesdk-js';
import Navigation from '../../../components/navigation/Navigation';
import Layout from 'antd/es/layout/layout';
import { useEffect, useRef, useState } from 'react';
import { Drawer, Flex } from 'antd';

const { Header } = Layout;

const config = {
    license: 'mtLT-_GJwMhE7LDnO8KKEma7qSuzWuDxiKuQcxHKmz3fjaXWY2lT3o3Z2VdL5twm',
    userId: 'guides-user',
    // Enable local uploads in Asset Library
    callbacks: { onUpload: 'local' }
};

const Editor = () => {
    
    const cesdk_container = useRef(null);
    const [cesdk, setCesdk] = useState(null);
    useEffect(() => {
        if (!cesdk_container.current) return;

        let cleanedUp = false;
        let instance;
        CreativeEditorSDK.create(cesdk_container.current, config).then(
            async (_instance) => {
            instance = _instance;
            if (cleanedUp) {
                instance.dispose();
                return;
            }

            await Promise.all([
                instance.addDefaultAssetSources(),
                instance.addDemoAssetSources({ sceneMode: 'Design' }),
            ]);
            
            await instance.createVideoScene();
    
            setCesdk(instance);
        }
    );
    const cleanup = () => {
    cleanedUp = true;
    instance?.dispose();
    setCesdk(null);
    };
    return cleanup;
    }, [cesdk_container]);

    return (
        <Navigation collapsedStatus>
            <Header>
                <Flex justify="space-between" align="center">
                    <div>
                        <div className="main-title">Video Editor</div>
                    </div>
                </Flex>
            </Header>
            <div className="main">
                <div
                    ref={cesdk_container}
                    style={{ width: '100%', height: '80vh' }}
                >
                </div>
            </div>
        </Navigation>
        
    );    
}

export default Editor;