import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message, Upload, Flex, Breadcrumb } from 'antd';
import { IoHome } from 'react-icons/io5';
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import "./style.css";
import { UserManager } from '../../utils/tools/user-manager/UserManager';

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT;

const ReportUpload = () => {
    const [file, setFile] = useState(null);
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleSave = async () => {
        if (loggedUser) {
            if (!file) {
                message.error('No file selected.');
                return;
            }
    
            const formData = new FormData();
            formData.append('file', file);
    
            try {
                const response = await axios.post(`${apiUrl}/api/v1/reports/upload-report`, formData, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                message.success(`${file.name} file uploaded successfully`);
                setFile(null); // Reset the file state after successful upload
            } catch (error) {
                console.error('Upload Error:', error);
                message.error(`${file.name} file upload failed.`);
            }
        }
    };

    return (
        <>
        <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Upload Report</div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Carica il report in formato .csv',
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Flex gap="large">
                        <Upload name='file'
                            accept='.csv'
                            beforeUpload={(file) => {
                                setFile(file);
                                return false;
                            }}
                            onRemove={() => setFile(null)}
                            className="uploader"
                        >
                            <Flex align="center" gap="large">
                                <Button className="select-button">Select file</Button>
                                <div>Carica un file csv</div>
                            </Flex>
                        </Upload>
                        <button onClick={handleSave} disabled={!file} className="add-button upload-report">
                            Save
                        </button>
                    </Flex>
                </div>
            </Navigation>
        </>
    )
}

export default ReportUpload;