import { MdAccountCircle } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { IoExitSharp } from "react-icons/io5";
import { useState, useEffect } from "react";
import {UserManager} from "../tools/user-manager/UserManager";
import {permissions} from "../tools/roles/roles"

export const ProfileMenuItems = () => {
    const [permissionsRole, setPermissionsRole] = useState()

    const showVideoSection = window.AppConfig.SHOW_VIDEO_SECTION

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setPermissionsRole(permissions[user.user.ruolo]);
        }
    }, []);

    const menuItems = [
        {
            key: "account",
            icon: <MdAccountCircle />,
            label: "Account",
            permission: true
        },
        {
            key: "settings",
            icon: <IoMdSettings />,
            label: "Settings",
            permission: showVideoSection && permissionsRole?.canSeeSettings,
            children: [
                {
                    key: 'tag',
                    label: 'Tags',
                },
                {
                    key: 'category',
                    label: 'Categorie',
                }
            ]
        },
        {
            key: "logout",
            icon: <IoExitSharp />,
            label: "Logout",
            permission: true
        }
    ]

    return menuItems
                .filter(item => item.permission)
                .map(({ permission, ...rest }) => rest);
}