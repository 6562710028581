import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Form, Input, Card, Select, Breadcrumb } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import axios from "axios";
import { useState, useEffect } from "react";
import BackButton from "../../components/navigation/BackButton";
import Loading from "../../components/loading/Loading";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;
const { TextArea } = Input;

const apiUrl = window.AppConfig.API_ENDPOINT

const NewWidget = () => {
    const [dataset, setDataset] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);
    
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedUser) {
            if (id) {
                axios.post(`${apiUrl}/api/v1/player/get-detail`, {id : parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
            } else {
                setIsLoading(false)
            }
        }
    }, [loggedUser])

    const handleSubmit = () => {
        if (loggedUser) {
            form.validateFields()
            .then(values => {
                if (id) {
                    axios.put(`${apiUrl}/api/v1/player/upsert`, {id: parseInt(id), ...values, status: "attivo"}, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`
                        }
                    })
                    .then(response => {
                        navigate(`/widget`)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                } else {
                    axios.put(`${apiUrl}/api/v1/player/upsert`, {id: 0, ...values}, {
                        headers: {
                            'Authorization': `Bearer ${loggedUser.token.accessToken}`
                        }
                    })
                    .then(response => {
                        navigate(`/widget`)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                }
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{id ? "Modifica Widget" : "Crea Widget"}</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <div>
                                <button className="add-button" onClick={() => handleSubmit()}>Salva</button>
                            </div>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/widget",
                            title: 'Widget',
                        },
                        {
                            title: id ? "Modifica Widget" : "Crea Widget",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Card title="Anagrafica" className="form-card">
                        <Flex vertical gap="large">
                            <Form
                                layout="inline"
                                name="widget"
                                form={form}
                                fields={
                                    [
                                        {
                                            name: ["sito"],
                                            value: dataset?.sito
                                        },
                                        {
                                            name: ["contentId"],
                                            value: dataset?.contentId
                                        },
                                        {
                                            name: ["playerTheme"],
                                            value: dataset?.playerTheme
                                        },
                                        {
                                            name: ["isDebug"],
                                            value: dataset?.isDebug
                                        },
                                        {
                                            name: ["isPortrait"],
                                            value: dataset?.isPortrait
                                        },
                                        {
                                            name: ["urlVideo"],
                                            value: dataset?.urlVideo
                                        },
                                        {
                                            name: ["imgPoster"],
                                            value: dataset.imgPoster
                                        },
                                        {
                                            name: ["adsUrl"],
                                            value: dataset?.adsUrl
                                        }
                                        
                                    ]
                            }
                            >
                                <Flex vertical gap="large" style={{width: "100%"}}>
                                    <Flex vertical gap="small">
                                        <Flex>
                                            <Form.Item
                                                name="sito"
                                                style={{width: "18%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input site!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Sito" />
                                            </Form.Item>

                                            <Form.Item
                                                name="contentId"
                                                style={{width: "18%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input contact id!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Content Id" />
                                            </Form.Item>

                                            <Form.Item
                                                name="playerTheme"
                                                style={{width: "18%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input theme!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="tema" />
                                            </Form.Item>
                                            <Form.Item
                                                name="isDebug"
                                                style={{width: "18%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input debug!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={[{value: true, label: "Attivo"}, {value: false, label: "Non attivo"}]}
                                                    placeholder="Debug"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="isPortrait"
                                                style={{width: "18%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input portrait!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    optionFilterProp="label"
                                                    showSearch
                                                    options={[{value: true, label: "Attivo"}, {value: false, label: "Non attivo"}]}
                                                    placeholder="Portrait"
                                                />
                                            </Form.Item>
                                        </Flex>
                                        <Form.Item
                                                name="urlVideo"
                                                style={{width: "98%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input url video!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="URL Video" />
                                        </Form.Item>
                                        <Form.Item
                                                name="imgPoster"
                                                style={{width: "98%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input poster image!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Poster Image" />
                                        </Form.Item>
                                        <Form.Item
                                                name="adsUrl"
                                                style={{width: "98%"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input ads url!',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={3} placeholder="Ads URL" />
                                        </Form.Item>
                                    </Flex>
                                </Flex>
                                
                            </Form>
                        </Flex>
                    </Card>
                </div>
            </Navigation>
        </>
    )
}

export default NewWidget;