import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Form, Select, Input, Collapse, Card, InputNumber, Button, Breadcrumb, TreeSelect } from 'antd';
import "./style.css"
import { useNavigate, useParams } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import { convertFloat, convertStringFloat } from "../../utils/tools/Tools";
import BackButton from "../../components/navigation/BackButton";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Website = ({ data, treeData, form }) => {
    const { id } = useParams();

    let partnerOptions = [];

    if (data) {
        partnerOptions = [{ value: data.publisherInfoId, label: data.publisherInfoNome }];
    } else {
        partnerOptions = treeData;
    }

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                nome: data?.nome,
                url: data?.url,
                status: data?.status,
                publisherInfoId: data?.publisherInfoId,
            });
        }
    }, [data, form]);

    return (
        <Form
            layout="inline"
            name="website"
            form={form}
            initialValues={{
                nome: data?.nome,
                url: data?.url,
                status: data?.status,
                publisherInfoId: data?.publisherInfoId,
            }}
        >
            <Flex vertical gap="large" style={{ width: "100%" }}>
                <Flex>
                    <Form.Item
                        name="nome"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>

                    <Form.Item
                        name="url"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input website!',
                            },
                        ]}
                    >
                        <Input placeholder="Sito" />
                    </Form.Item>
                    <Form.Item
                        name="publisherInfoId"
                        style={{ width: "24%" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input dealership!',
                            },
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="Concessionaria/Partner"
                            disabled={id}
                            allowClear
                            treeDefaultExpandAll
                            treeData={partnerOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        style={{ width: "22%" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input status!',
                            },
                        ]}
                    >
                        <Select
                            optionFilterProp="label"
                            showSearch
                            options={[
                                { value: "attivo", label: "Attivo" },
                                { value: "bozza", label: "Bozza" },
                                { value: "cancellato", label: "Cancellato" },
                            ]}
                            placeholder="Stato"
                        />
                    </Form.Item>
                </Flex>
            </Flex>
        </Form>
    );
};

const Revenue = ({type, data, form, setRevenueValues}) => {
    const [value, setValue] = useState(
        data ? {
            percentuale: convertFloat(data?.percentuale),
            fissoECPM: convertFloat(data?.fissoECPM),
            percentualeParent: convertFloat(data?.percentualeParent),
            fissoECPMParent: convertFloat(data?.fissoECPMParent)
        } : {
            percentuale: convertFloat("0,00"),
            fissoECPM: convertFloat("0,00"),
            percentualeParent: convertFloat("0,00"),
            fissoECPMParent: convertFloat("0,00")
        }
    );

    useEffect(() => {
        data &&
        setValue({
            percentuale: convertFloat(data?.percentuale),
            fissoECPM: convertFloat(data?.fissoECPM),
            percentualeParent: convertFloat(data?.percentualeParent),
            fissoECPMParent: convertFloat(data?.fissoECPMParent)
        });
    }, [data]);

    const onChange = (value, key) => {
        const numericalValue = parseFloat(value);
        const fixedValue = parseFloat(numericalValue.toFixed(2));
        setValue(prev => ({ ...prev, [key]: fixedValue }));
        setRevenueValues(prev => ({ ...prev, [key]: fixedValue }));
    };

    return (
        <Form
            layout="inline"
            name={`revenue ${type}`}
            form={form}
        >
            <Flex vertical gap="large" style={{width: "100%"}}>
                <Flex>
                    <Flex vertical>
                        <h3>Fixed ECPM</h3>
                        <Form.Item
                            name="ecpm"
                            style={{width: "80%"}}
                        >
                            <Flex>
                                <Button className="value-change-button1" onClick={() => onChange(Math.max((type === 1 ? value.fissoECPM : value.fissoECPMParent) - 0.01, 0), type === 1 ? "fissoECPM" : "fissoECPMParent")}>
                                    -
                                </Button>
                                <InputNumber
                                    min={0}
                                    defaultValue={type === 1 ? value.fissoECPM : value.fissoECPMParent}
                                    value={type === 1 ? value.fissoECPM : value.fissoECPMParent}
                                    className="input-number"
                                    onChange={onChange}
                                    formatter={value => `${value}`.replace(',', '.')}
                                    parser={value => value.replace('.', ',')}
                                    style={{width: "50%"}}
                                />
                                <Button className="value-change-button2" onClick={() => onChange((type === 1 ? value.fissoECPM : value.fissoECPMParent) + 0.01, type === 1 ? "fissoECPM" : "fissoECPMParent")}>
                                    +
                                </Button>
                            </Flex>
                            
                        </Form.Item>
                    </Flex>
                    <Flex vertical>
                        <h3>Percentage</h3>
                        <Form.Item
                            name="ecpm"
                            style={{width: "80%"}}
                        >
                            <Flex>
                                <Button className="value-change-button1" onClick={() => onChange(Math.max((type === 1 ? value.percentuale : value.percentualeParent) - 0.01, 0), type === 1 ? "percentuale" : "percentualeParent")}>
                                    -
                                </Button>
                                <InputNumber
                                    min={0}
                                    defaultValue={type === 1 ? value.percentuale : value.percentualeParent}
                                    value={type === 1 ? value.percentuale : value.percentualeParent}
                                    onChange={onChange}
                                    className="input-number"
                                    formatter={value => `${value}`.replace(',', '.')}
                                    parser={value => value.replace('.', ',')}
                                    style={{width: "50%"}}
                                />
                                <Button className="value-change-button2" onClick={() => onChange((type === 1 ? value.percentuale : value.percentualeParent) + 0.01, type === 1 ? "percentuale" : "percentualeParent")}>
                                    +
                                </Button>
                            </Flex>
                            
                        </Form.Item>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    )
}

const generateNestedList = (parents, children) => {
    const nestedList = parents.map(parent => {
        const matchingChildren = children.filter(child => child.text1 === parent.value);
        
        const result = {
            value: parseInt(parent.value),
            title: parent.text,
            children: matchingChildren.map(child => ({
                value: parseInt(child.value),
                title: child.text
            }))
        };
        
        return result;
    });

    return nestedList;
};

const NewInventory = () => {
    const [dataset, setDataset] = useState()
    const [dealershipList, setDealershipList] = useState([])
    const [publisherList, setPublisherList] = useState([])
    const [fullList, setFullList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [form] = Form.useForm();
    const [revenueValues, setRevenueValues] = useState({
        "fissoECPM": 0.00,
        "percentuale": 0.00,
        "fissoECPMParent": 0.00,
        "percentualeParent": 0.00
    })
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleSubmit = () => {
        if (loggedUser) {
            const parsedRevenues = {
                percentuale: convertStringFloat(revenueValues.percentuale),
                fissoECPM: convertStringFloat(revenueValues.fissoECPM),
                percentualeParent: convertStringFloat(revenueValues.percentualeParent),
                fissoECPMParent: convertStringFloat(revenueValues.fissoECPMParent)
            }
            form.validateFields()
            .then(values => {
                axios.put(`${apiUrl}/api/v1/inventory/upsert`, {id: parseInt(id) ?? 0, ...parsedRevenues, ...values}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                .then(response => {
                    navigate(`/inventory`)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            })
            .catch(errorInfo => {
                console.log('Error:', errorInfo);
            });
        }
    }

    useEffect(() => {
        if (dealershipList.length > 0) {
            const nestedList = generateNestedList(dealershipList, publisherList);
            setFullList(nestedList);
        }
    }, [dealershipList, publisherList]);

    const navigate = useNavigate();
    
    const { id } = useParams();

    useEffect(() => {
        if (loggedUser) {
            if (id) {
                axios.post(`${apiUrl}/api/v1/inventory/get-detail`, {id: parseInt(id)}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDataset(response.data)
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false)
                    });
            } else {
                axios.post(`${apiUrl}/api/v1/init/list-concessionarie`, {}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setDealershipList(response.data)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
        
                axios.post(`${apiUrl}/api/v1/init/list-publishers`, {}, {
                    headers: {
                        'Authorization': `Bearer ${loggedUser.token.accessToken}`
                    }
                })
                    .then(response => {
                        setPublisherList(response.data)
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
                setIsLoading(false)
            }
        }
    }, [loggedUser])

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">{id ? dataset?.nome : "Crea Inventory"}</div>
                        </div>
                        <div className="filter-section">
                            <BackButton />
                            <button onClick={handleSubmit} htmltype="submit" className="add-button">Salva</button>
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            href: "/inventory",
                            title: 'Inventory',
                        },
                        {
                            title: id ? dataset?.nome : "Crea Inventory",
                            className: 'active-breadcrumb'
                        }
                        ]}
                    />
                    <Flex vertical gap="large">
                        <Collapse
                            expandIconPosition="end"
                            className="form-collapse"
                            items={[{ key: 'website', label: 'Website', children: <Website data={dataset} treeData={fullList} form={form}/> }]}
                            defaultActiveKey="website"
                            expandIcon={({ isActive }) => isActive ? <img alt="active-accordion" src="/custom-icons/CloseAccordion.svg" /> : <img alt="active-accordion" src="/custom-icons/OpenAccordion.svg" />}
                        />
                        <Flex gap="small">
                            <Card className="inventory-card" title="Revenue Share">
                                <Revenue type={1} data={dataset} setRevenueValues={setRevenueValues}/>
                            </Card>
                            <Card className="inventory-card" title="Revenue Share Parent">
                                <Revenue type={2} data={dataset} setRevenueValues={setRevenueValues}/>
                            </Card>
                        </Flex>
                    </Flex>
                    
                </div>
            </Navigation>
        </>
    )
}

export default NewInventory;