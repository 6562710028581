import { Table, Flex, Input } from "antd";
import { IoCreateOutline, IoTrashOutline, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Statistic from "antd/es/statistic/Statistic";
import dayjs from "dayjs";
import "./style.css"
import { useState, useEffect } from "react";
import axios from "axios";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const apiUrl = window.AppConfig.API_ENDPOINT

const InventoryTable = ({data, setFiltersApi, pageInfo, trigger}) => {
    const [tableData, setTableData] = useState(data)
    const [searchQuery, setSearchQuery] = useState('');
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
    };

    useEffect(() => {
        setTableData(data)
    }, [data])

    const deleteEntity = (id) => {
        if (loggedUser) {
            axios.delete(`${apiUrl}/api/v1/inventory/delete`, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                },
                data: { id: parseInt(id) } 
            })
            .then(response => {
                trigger(new Date());
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    useEffect(() => {
        if (searchQuery.length > 2) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: searchQuery
            }));
        }

        if (searchQuery.length === 0) {
            setFiltersApi(prevState => ({
                ...prevState, 
                Search: ""
            }));
        } 
    }, [searchQuery])

    const navigate = useNavigate()

    const columns = [
        {
            title: 'Date',
            dataIndex: 'dtCreazione',
            width: 150,
            render: (row, elem) => dayjs(elem.dtCreazione).format('DD/MM/YYYY'),
        },
        {
            title: 'Name',
            dataIndex: 'nome',
            className: "name-col",
            width: 150,
        },
        {
            title: 'Url',
            dataIndex: 'url',
            width: 200,
        },
        {
            title: 'Publisher',
            dataIndex: 'publisherInfoNome',
            width: 200,
        },
        {
            title: 'Percentage',
            dataIndex: 'percentuale',
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.percentuale} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Fixed',
            dataIndex: 'fissoECPM',
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.fissoECPM} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Perc. Parent',
            dataIndex: 'percentualeParent',
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.percentualeParent} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Fixed Parent',
            dataIndex: 'fissoECPMParent',
            render: (row, elem) => <Statistic className="table-statistic" groupSeparator="." value={elem.fissoECPMParent} decimalSeparator="," precision={2}/>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (row, elem) => elem.status ? <div className="active-col">Attivo</div> : <div className="inactive-col">Disattivo</div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (row, elem) => <Flex gap="small" className="action-menu">
                    <IoCreateOutline size={20} onClick={() => navigate(`/inventory/${elem.id}`)} />
                    <IoTrashOutline size={20} onClick={() => deleteEntity(elem.id)} />
                </Flex>
        }
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const totalElements = pageInfo?.totalItems;
        
    return (
        <>
            <Input value={searchQuery} onChange={handleChange} className="table-search" size="large" placeholder="Ricerca" prefix={<IoSearch />} />
            <Table
                rowKey="id"
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 100,
                    total: totalElements,
                }}
                scroll={{y: 840, x: 1300}}
                rowClassName={rowClassName}
                pageSize={pageInfo?.pager?.totalPages}
                onChange={(data) => {
                    setFiltersApi(prevState => ({
                        ...prevState, 
                        Page: data.current
                    }));
                }}
            />
        </>
    )
}

export default InventoryTable;