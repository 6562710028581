import { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import Layout from "antd/es/layout/layout";
import { Flex, Breadcrumb } from 'antd';
import DealershipTable from "../../components/table/DealershipTable";
import { useNavigate } from "react-router-dom";
import { IoHome, IoAddCircle, IoPrint } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import { UserManager } from "../../utils/tools/user-manager/UserManager";

const { Header } = Layout;

const apiUrl = window.AppConfig.API_ENDPOINT

const Dealership = () => {
    const [dataset, setDataset] = useState([])
    const [filtersApi, setFiltersApi] = useState({
        "Search":"",
        "Page": 1,
    }) 
    const [pager, setPager] = useState()
    const [trigger, setTrigger] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const user = UserManager();
        if (user) {
            setLoggedUser(user);
        }
    }, []);

    useEffect(() => {
        if (loggedUser) {
            axios.post(`${apiUrl}/api/v1/concessionarie/get-list`, filtersApi, {
                headers: {
                    'Authorization': `Bearer ${loggedUser.token.accessToken}`
                }
            })
                .then(response => {
                    setDataset(response.data.data)
                    setPager(response.data.pager)
                    setIsLoading(false)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }, [filtersApi, trigger, loggedUser])

    const navigate = useNavigate();

    return (
        <>
            <Loading visible={isLoading} />
            <Navigation>
                <Header>
                    <Flex justify="space-between" align="center">
                        <div>
                            <div className="main-title">Concessionarie</div>
                        </div>
                        <div className="filter-section">
                            <button onClick={() => navigate("/dealership/new")} className="add-button">
                                Aggiungi
                                <IoAddCircle size={15} />
                            </button>
                            {/* <button className="print-button">
                                Stampa
                                <IoPrint size={15} />
                            </button> */}
                        </div>
                    </Flex>
                </Header>
                <div className="main">
                    <Breadcrumb
                        separator=">"
                        items={[
                        {
                            href: "/",
                            title: (
                                <Flex gap="small" align="center">
                                    <IoHome />
                                    Home
                                </Flex>
                            ),
                        },
                        {
                            title: 'Concessionarie',
                            className: 'active-breadcrumb'
                        },
                        ]}
                    />
                    <DealershipTable data={dataset} setFiltersApi={setFiltersApi} setTrigger={setTrigger} pageInfo={pager}/>
                </div>
            </Navigation>
        </>
    )
}

export default Dealership;