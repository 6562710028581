import Cookies from 'js-cookie';

export const UserManager = () => {
    let user = Cookies.get('loggedUser');

    if (user) {
        return JSON.parse(user);
    } else {
        if (window.location.pathname !== "/login") {
            window.location.href = "/login";
        }
    }
}

